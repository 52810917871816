import React, { useState } from 'react';
import { Row, Col, Input, Button, Modal, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/AnalizarPaciente.css';
import { DeleteOutlined } from '@ant-design/icons';

const AnalizarPaciente = () => {
    const navigate = useNavigate();
    const [imagenes, setImagenes] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const [formValues, setFormValues] = useState({
        nombre: '',
        edad: '',
        sexo: '',
        especie: '',
        raza: '',
        anamnesis: ''
    });

    const [formErrors, setFormErrors] = useState({});

    const handleAnalizar = () => {
        const errors = {};
        if (!formValues.nombre) errors.nombre = true;
        if (!formValues.edad) errors.edad = true;
        if (!formValues.sexo) errors.sexo = true;
        if (!formValues.especie) errors.especie = true;
        if (!formValues.raza) errors.raza = true;

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            navigate('/analisis');
        } else {
            message.error('Por favor, completa todos los campos obligatorios antes de continuar.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
        setFormErrors({
            ...formErrors,
            [name]: false,
        });
    };

    const handleImagenesChange = (event) => {
        const files = Array.from(event.target.files);
        const validFiles = files.filter(file => file.type.startsWith('image/'));

        if (imagenes.length + validFiles.length <= 6) {
            setImagenes(prevImagenes => [...prevImagenes, ...validFiles]);
        } else {
            message.error('Solo puedes subir un máximo de 6 imágenes.');
        }
    };

    const handleRemoveImage = (index) => {
        setImagenes(prevImagenes => prevImagenes.filter((_, i) => i !== index));
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedImage(null);
    };

    return (
        <div className="body">
            <Row className='content'>
                <Col lg={13} sm={24} xs={24} className="leftcolumn">
                    <Col md={12} sm={20} xs={20}>
                        <Row className='logo-container'>
                            <p className='bluetitle decrease analizar-title'>Analizar Paciente</p>
                        </Row>
                        <Row>
                            <Input 
                                placeholder='Nombre *' 
                                className={`loginInput2 ${formErrors.nombre ? 'input-error' : ''}`}
                                name="nombre"
                                value={formValues.nombre}
                                onChange={handleInputChange}
                            />
                        </Row>
                        <Row justify={'space-between'}>
                            <Col className="custom-col-11-6">
                                <Input 
                                    placeholder='Edad *' 
                                    className={`loginInput2 ${formErrors.edad ? 'input-error' : ''}`}
                                    name="edad"
                                    value={formValues.edad}
                                    onChange={handleInputChange}
                                />
                            </Col>
                            <Col className="custom-col-11-6">
                                <Input 
                                    placeholder='Sexo *' 
                                    className={`loginInput2 ${formErrors.sexo ? 'input-error' : ''}`}
                                    name="sexo"
                                    value={formValues.sexo}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row justify={'space-between'}>
                            <Col className="custom-col-11-6">
                                <Input 
                                    placeholder='Especie *' 
                                    className={`loginInput2 ${formErrors.especie ? 'input-error' : ''}`}
                                    name="especie"
                                    value={formValues.especie}
                                    onChange={handleInputChange}
                                />
                            </Col>
                            <Col className="custom-col-11-6">
                                <Input 
                                    placeholder='Raza *' 
                                    className={`loginInput2 ${formErrors.raza ? 'input-error' : ''}`}
                                    name="raza"
                                    value={formValues.raza}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Input 
                                placeholder='Anamnesis' 
                                className='loginInput2'
                                name="anamnesis"
                                value={formValues.anamnesis}
                                onChange={handleInputChange}
                            />
                        </Row>
                        <Row>
                            <Button className='analizar-button'>
                                <input
                                    id="upload-input"
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleImagenesChange}
                                />
                                <label htmlFor="upload-input" style={{ cursor: 'pointer' }}>Agregar imágenes</label>
                            </Button>
                        </Row>
                        <div>
                            <button className="button2 espaciar" onClick={handleAnalizar}>
                                Analizar
                            </button>
                        </div>
                    </Col>
                </Col>
                <Col lg={11} sm={24} xs={24} className="rightcolumn">
                    <div className='rightside-container4'>
                        <div className='image-grid'>
                            {imagenes.map((imagen, index) => (
                                <div key={index} className="image-preview">
                                    <img 
                                        src={URL.createObjectURL(imagen)} 
                                        alt={`Imagen ${index + 1}`} 
                                        className="uploaded-image" 
                                        onClick={() => handleImageClick(URL.createObjectURL(imagen))}
                                    />
                                    <Button className="remove-button" onClick={() => handleRemoveImage(index)}><DeleteOutlined/></Button>
                                </div>
                            ))}
                        </div>
                    </div>
                </Col>
            </Row>
            <Modal visible={isModalVisible} footer={null} onCancel={handleModalClose}>
                <img src={selectedImage} alt="Imagen ampliada" style={{ width: '100%' }} />
            </Modal>
        </div>
    );
};

export default AnalizarPaciente;

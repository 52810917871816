import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'antd';
import '../assets/styles/MainPage.css';
import ovejero from '../assets/images/ovejero.webp';
import logo2 from '../assets/images/logo2.svg';
import videoSample from '../assets/videos/video.mp4';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
    const navigate = useNavigate();
    
    const handleLogin = () => {
        navigate('/login')
    }

    const useOnScreen = (ref) => {
        const [isIntersecting, setIntersecting] = useState(false);

        useEffect(() => {
            const observer = new IntersectionObserver(
                ([entry]) => setIntersecting(entry.isIntersecting)
            );

            if (ref.current) {
                observer.observe(ref.current);
            }

            return () => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            };
        }, [ref]);

        return isIntersecting;
    };

    const Counter = ({ endNumber }) => {
        const [count, setCount] = useState(0);
        const ref = useRef();
        const isVisible = useOnScreen(ref);

        useEffect(() => {
            if (isVisible) {
                let start = 0;
                const duration = 2000;
                const increment = endNumber / (duration / 10);

                const timer = setInterval(() => {
                    start += increment;
                    if (start >= endNumber) {
                        setCount(endNumber);
                        clearInterval(timer);
                    } else {
                        setCount(Math.floor(start));
                    }
                }, 10);

                return () => clearInterval(timer);
            }
        }, [isVisible, endNumber]);

        return (
            <p className="counter" ref={ref}>+{count}</p>
        );
    };
    return (
        <div className="body">
            <Row className='content'>
                <Col lg={10} sm={24} xs={24} className="leftside">
                    <div >
                        <img src={ovejero} alt="ovejero" className="dogimage" />
                    </div>
                </Col>
                <Col lg={14} sm={24} xs={24} className="rightside">
                    <Col span={24} >
                        <Col span={24} className='rightside-container'>
                            <div className="logo-container">
                                <img src={logo2} alt="Logo" className="logo" />
                            </div>
                            <p className="subtitle">La herramienta perfecta para el diagnóstico de imágenes</p>
                            <div className="button-container">
                                <button className="button" onClick={handleLogin}>Prueba en línea &rarr;</button>
                            </div>
                        </Col>
                    </Col>
                </Col>
                <Col span={24} className="secondRow">
                    <Row justify={'center'}>
                        <Col span={24}>
                            <p className='bluetitle'>
                                ¡Ya no estarás solo en las radiografías!
                            </p>
                        </Col>
                        <Col span={18}>
                            <p className='whitesubtitle'>
                                Con la ayuda de un potente modelo de inteligencia artificial entrenado meticulosamente
                                realizar los informes de un paciente será más fácil que nunca.
                            </p>
                        </Col>
                        <Col span={24}>
                            <div className="video-container">
                                <video className="video" controls>
                                    <source src={videoSample} type="video/mp4" />
                                    Tu navegador no soporta el elemento de video.
                                </video>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className="thirdRow">
                    <Row justify={'center'}>
                        <Col span={24}>
                            <p className='bluetitle'>
                                AI diseñada para veterinarios
                            </p>
                        </Col>
                        <Col span={18}>
                            <p className='item2'>
                                ¡Facil de usar, suelte su imagen y listo!
                            </p>
                            <Row justify={'space-around'} className='itemsRow'>
                                <Col span={6}>
                                    <p className='item'>
                                        Resultados
                                        Instantaneos
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <p className='item'>
                                        Todos los
                                        Formatos de Imagen
                                    </p>
                                    <p className='subitem'>
                                        DIOCOM / JPG / PNG
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <p className='item'>
                                        Informes
                                        Automaticos
                                    </p>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className="forthRow">
                    <Row justify={'center'}>
                        <Col span={24}>
                            <p className='bluetitle'>
                                Regiones anatómicas procesadas
                            </p>
                        </Col>
                        <Col lg={18} sm={22} xs={22}>
                            <Row justify={'space-around'} className='itemsRow2'>
                                <Col span={6}>
                                    <div className='circle'></div>
                                    <p className='item3'>
                                        Abdomen
                                    </p>
                                    <p className='subitem2'>
                                        <span className='uppercase'>13 patrones</span> detectados
                                    </p>
                                    <p className='subitem3'>
                                        <span className='uppercase'>3 sugerencias</span> de diagnóstico diferencial
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <div className='circle'></div>
                                    <p className='item3'>
                                        Tórax
                                    </p>
                                    <p className='subitem2'>
                                        <span className='uppercase'>13 patrones</span> detectados
                                    </p>
                                    <p className='subitem3'>
                                        <span className='uppercase'>3 sugerencias</span> de diagnóstico diferencial
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <div className='circle'></div>
                                    <p className='item3'>
                                        Displasia de cadera
                                    </p>
                                    <p className='subitem2'>
                                        <span className='uppercase'>13 patrones</span> detectados
                                    </p>
                                    <p className='subitem3'>
                                        <span className='uppercase'>3 sugerencias</span> de diagnóstico diferencial
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className="fifthRow">
                    <Row justify={'center'}>
                        <Col span={24}>
                            <p className='bluetitle'>
                                DiagnovetAI en números
                            </p>
                        </Col>
                        <Col lg={18} sm={22} xs={22}>
                            <Row justify={'space-around'} className='itemsRow3'>
                                <Col span={6} >
                                    <div className="counter-container">
                                        <Counter endNumber={1000} />
                                    </div>
                                    <p className='item'>
                                        Radiografías analizadas
                                    </p>

                                </Col>
                                <Col span={6} >
                                    <div className="counter-container">
                                        <Counter endNumber={500} />
                                    </div>
                                    <p className='item'>
                                        Usuarios
                                    </p>

                                </Col>
                                <Col span={6} >
                                    <div className="counter-container">
                                        <Counter endNumber={1200} />
                                    </div>
                                    <p className='item'>
                                        Radiografías analizadas
                                    </p>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default LandingPage;

import React from 'react';
import '../assets/styles/Footer.css';
import { Col, Row } from 'antd';
import { FacebookOutlined, InstagramOutlined, TwitterOutlined, LinkedinOutlined } from '@ant-design/icons';


function Footer() {
  return (
    <Row >
      <Col span={24} className="footer">
        <Row className="center">
          <Col><a href="https://www.instagram.com" target="_blank"
            rel="noreferrer noopener" className="circle2"><LinkedinOutlined
              className="iconsize" /></a></Col>
          <Col><a href="https://www.instagram.com" target="_blank"
            rel="noreferrer noopener" className="circle2"><InstagramOutlined
              className="iconsize" /></a></Col>
          <Col><a href="https://www.facebook.com" target="_blank"
            rel="noreferrer noopener" className="circle2"><FacebookOutlined
              className="iconsize" /></a></Col>
          <Col><a href="https://www.instagram.com" target="_blank"
            rel="noreferrer noopener" className="circle2"><TwitterOutlined
              className="iconsize" /></a></Col>
        </Row>
      </Col>
      <Col span={24} className="footer2">
        <Row justify={'space-between'} className='lastRow'>
          <Col className='leftcol'>
            <p className='titlee'>
              Encontranos
            </p>
            <p className='subitemm'>
              Cnel. Juan P. Pringles 365, General Paz, Córdoba, Argentina
            </p>
            <p className='subitemm'>
              Numero de telefono
            </p>
            <p className='subitemm'>
              mail.ejemplo@gmail.com
            </p>
          </Col>
          <Col className='rightcol'>
            <p className='subitemm2'>
              Copyright © 2024 DiagnovetAI
            </p>
            <p className='subitemm2'>
              All rights reserved.
            </p>
            <p className='subitemm2'>
              diagnovetai.com
            </p>
          </Col>
        </Row>
        <Row >
          <Col span={24} className='alinear'>
            <p className='subitemm'>
              Desarrollado por LM Tech en Córdoba, Argentina
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Footer;

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const useAuthCookie = () => {
    const accessToken = cookies.get('authToken');
    return !!accessToken;
};

export const logout = () => {
    cookies.remove('authToken', { path: '/' });
};


import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Importa GoogleOAuthProvider
import Navbar from '../src/components/Navbar';
import Footer from '../src/components/Footer';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import AnalizarPaciente from './pages/AnalizarPaciente';
import { gapi } from 'gapi-script';
import Analisis from './pages/Analisis';

function App() {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: ""
      });
    }
    gapi.load('client:auth2', start);
  }, []); 

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/sign-up' element={<SignupPage />} />
            <Route path='/dashboard' element={<AnalizarPaciente />} />
            <Route path='/Analisis' element={<Analisis />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;

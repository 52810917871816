import React, { useEffect } from 'react';
import { Row, Col, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import Cookies from 'universal-cookie';
import golden from '../assets/images/golden.webp';
import '../assets/styles/LoginPage.css';
import { useAuthCookie } from '../utils/Cookies';

const LoginPage = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const token = useAuthCookie();

    useEffect(() => {
        if (token) {
            navigate('/dashboard');
        }
    }, [navigate, cookies]);

    const handleLoginSuccess = async (response) => {
        try {
            const token = response.credential;
            cookies.set('authToken', token, { path: '/', expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) });
            message.success('Inicio de sesión exitoso');
            navigate('/dashboard');
        } catch (error) {
            console.error('Error al establecer la cookie:', error);
            message.error('Error al iniciar sesión');
        }
    };

    const handleLoginFailure = (error) => {
        console.error('Login Failed:', error);
        message.error('Error de inicio de sesión'); 
    };

    return (
        <div className="body">
            <Row className='content'>
                <Col lg={10} sm={24} xs={24} className="leftside">
                    <div>
                        <img src={golden} alt="ovejero" className="dogimage2" />
                    </div>
                </Col>
                <Col lg={14} sm={24} xs={24} className="rightside">
                    <Col span={24}>
                        <Col span={24} className='rightside-container2'>
                            <div className="logo-container">
                                <p className='bluetitle decrease'>Inicia Sesión</p>
                            </div>
                            <div className="button-container">
                                <GoogleLogin
                                    onSuccess={handleLoginSuccess}
                                    onFailure={handleLoginFailure}
                                />
                            </div>
                        </Col>
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default LoginPage;

import React, { useState } from 'react';
import { Row, Col, Image, Button, Slider, Input } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import '../assets/styles/Analisis.css';
import image1 from '../assets/images/CV16133737260000 (1).jpg';
import image2 from '../assets/images/CV16133737260000.jpg';
import image3 from '../assets/images/CV16133737260001.jpg';
import image4 from '../assets/images/CV16133737260002.jpg';

const Analisis = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [invert, setInvert] = useState(0);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

    const images = [image1, image2, image3, image4];

    const handleZoomIn = () => setZoom(zoom + 0.1);
    const handleZoomOut = () => setZoom(zoom > 1 ? zoom - 0.1 : 1);
    const handleInvertChange = (value) => setInvert(value);

    const handleMouseDown = (e) => {
            e.preventDefault();  // Evitar selección de imagen
            setDragging(true);
            setStartPosition({ x: e.clientX - position.x, y: e.clientY - position.y });
    };

    const handleMouseMove = (e) => {
        if (dragging) {
            const newX = e.clientX - startPosition.x;
            const newY = e.clientY - startPosition.y;

            // Limitar el movimiento para no arrastrar la imagen fuera de los bordes
            const limitedX = Math.min(Math.max(newX, -((zoom - 1) * 400)), (zoom - 1) * 400);
            const limitedY = Math.min(Math.max(newY, -((zoom - 1) * 296)), (zoom - 1) * 296);

            setPosition({ x: limitedX, y: limitedY });
        }
    };

    const handleMouseUp = () => setDragging(false);

    return (
        <div  onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
            <Row className="content content2">
                <Col md={2} sm={24} xs={24} className="columna-izq">
                    {images.map((img, index) => (
                        <div key={index} className="image-thumbnail">
                            <img
                                src={img}
                                alt={`Thumbnail ${index}`}
                                onClick={() => {
                                    setSelectedImage(img);
                                    setPosition({ x: 0, y: 0 });
                                    setZoom(1);
                                }}
                                className="thumbnail-image"
                            />
                        </div>
                    ))}
                </Col>
                <Col md={16} sm={24} xs={24} className="columna-cen">
                    {selectedImage ? (
                        <div className="image-container">
                            <Row className="superior-col">
                                <Col>
                                    <Button icon={<ZoomInOutlined />} onClick={handleZoomIn} className="zoom" />
                                    <Button icon={<ZoomOutOutlined />} onClick={handleZoomOut} className="zoom" />
                                </Col>
                                <Slider
                                    min={0}
                                    max={100}
                                    value={invert}
                                    onChange={handleInvertChange}
                                    className="invert-slider"
                                />
                            </Row>
                            <div
                                className="image-wrapper"
                                onMouseDown={handleMouseDown}
                                style={{
                                    cursor: zoom > 1 ? (dragging ? 'grabbing' : 'grab') : 'default',
                                }}
                            >
                                <Image
                                    src={selectedImage}
                                    alt="Selected"
                                    preview={false}
                                    className="full-image no-select"
                                    style={{
                                        transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
                                        filter: `invert(${invert}%)`,
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <p className="default-text">Seleccione una imagen para verla en detalle</p>
                    )}
                </Col>
                <Col md={6} sm={24} xs={24} className="columna-der">
                    <Row justify={'center'}>
                        <Col span={20}>
                            <Row className="input-row">
                                <h1 className='right-col-title'>VHS:</h1>
                                <div className="custom-input">
                                    <div className="left-part1"></div>
                                    <div className="right-split1">
                                        <div className="top-right1"></div>
                                        <div className="bottom-right1"></div>
                                    </div>
                                </div>
                            </Row>
                            <Row className="input-row">
                                <h1 className='right-col-title'>Patologías:</h1>
                                <div className="custom-input">
                                    <div className="left-part2"></div>
                                    <div className="right-split2">
                                        <div className="top-right2"></div>
                                        <div className="bottom-right2"></div>
                                    </div>
                                </div>
                                <div className="custom-input">
                                    <div className="left-part3"></div>
                                    <div className="right-split3">
                                        <div className="top-right3"></div>
                                        <div className="bottom-right3"></div>
                                    </div>
                                </div>
                            </Row>
                            <Row className="input-row">
                                <h1 className='right-col-title'>Otras observaciones:</h1>
                                <div className="custom-input">
                                    <div className="left-part2"></div>
                                    <div className="right-split2">
                                        <div className="top-right2"></div>
                                        <div className="bottom-right2"></div>
                                    </div>
                                </div>
                                <div className="custom-input">
                                    <div className="left-part3"></div>
                                    <div className="right-split3">
                                        <div className="top-right3"></div>
                                        <div className="bottom-right3"></div>
                                    </div>
                                </div>
                            </Row>
                            <Row justify={'center'}>
                                <Button className="generate-button">Generar Informe</Button>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Analisis;